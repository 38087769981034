import React from 'react'

import { ContainerStyled, TopNavStyled } from './styled'

const TopNav = () => (
  <ContainerStyled>
    <TopNavStyled>
      <ul>
        <li>Kinderwagen ab 10€ im Monat</li>
        <li>Kostenloser Versand</li>
        <li>Keine Kaution</li>
        <li>Bequeme Zahlung mit Paypal oder Kreditkarte</li>
      </ul>
    </TopNavStyled>
  </ContainerStyled>
)

export default TopNav
