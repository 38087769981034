import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Seo = ({ title, description }) => (
  <Helmet
    htmlAttributes={{
      lang: 'de',
    }}
  >
    <title>{title} | StrollMe</title>
    <meta name="description" content={description} />
  </Helmet>
)

Seo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Seo
