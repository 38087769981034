import styled, { keyframes } from 'styled-components'
import { theme, palette } from 'styled-tools'
import background from '../../../static/images/bg/bg_pattern.png'

const rtl = keyframes` 
0% {
    transform: translateX(100vw);
}

100% {
    transform: translateX(-100%);
}
`
export const ContainerStyled = styled.div`
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
  `
export const TopNavStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1150px;
  height: 2rem;
  color: ${palette('green')};
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  background-image: url(${background});
  background-size: cover;

  ${theme('media.mdUp')} {
    width: 100%;
    font-size: .8rem;
  }

  & ul {
    position: absolute;
    transform: translateX(100%);
    animation: ${rtl} 20s linear infinite;

    ${theme('media.mdUp')} {
      transform: translateX(0);
      animation: none;
    }
  }

  & li {
    display: inline;
    padding: 0 1rem;
    font-weight: bold;
    text-transform: uppercase;
    &:not(:last-child):after {
      position: absolute;
      margin-left: 1rem;
      content: '|';
    }
  }
`
